import {useEffect, useState} from "react";
import guanwang2 from './guanwang2.css'
import Divider from '@mui/material/Divider';
import * as React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';



export default function Guanwang() {

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const menu = ( <div>
        <Button
            id="basic-button"
            aria-controls={open ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onMouseEnter={handleClick}
            onClick={handleClose}
            color='black'
        >
            解决方案
        </Button>
        <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
                'aria-labelledby': 'basic-button',
            }}
        >
            <MenuItem onClick={handleClose}>解决方案1</MenuItem>
            <MenuItem onClick={handleClose}>解决方案2</MenuItem>
            <MenuItem onClick={handleClose}>解决方案3</MenuItem>
            <MenuItem onClick={handleClose}>解决方案4</MenuItem>
        </Menu>
    </div>
);


    const head = (
        <div className={'guanwang2_head'}>
            <div className={'guanwang2_head_logo'}>
                <div className={'guanwang2_head_logo_div'}>
                    <img className='guanwang2_head_logo_div_img'
                         src="https://pic3.zhimg.com/100/v2-2acc10ea7b4996188e63ed9c9a9503fe_r.jpg">
                    </img>
                    <div className='guanwang2_head_logo_div_text'>
                        启程旅游咨询
                    </div>
                </div>
            </div>
            <div className={'guanwang2_head_nav'}>
                <div className={'guanwang2_head_nav_div'}>
                    {menu}
                </div>
                <div className={'guanwang2_head_nav_div'}>
                    产品
                </div>
                <div className={'guanwang2_head_nav_div'}>行业</div>
                <div className={'guanwang2_head_nav_div'}>AI</div>
                <div className={'guanwang2_head_nav_div'}>定价</div>
                <div className={'guanwang2_head_nav_div'}>资源</div>
                <div className={'guanwang2_head_nav_div'}>关于我们</div>
            </div>
            <div className={'guanwang2_head_contact'}>
                <div className={'guanwang2_head_contact_div'}>联系我们</div>
            </div>
        </div>
    )

    const head2 = (
        <div className={'guanwang2_head'}>
            <div className={'guanwang2_head_logo'}>
                <div className={'guanwang2_head_logo_div'}>
                    <img className='guanwang2_head_logo_div_img'
                         src="https://pic3.zhimg.com/100/v2-2acc10ea7b4996188e63ed9c9a9503fe_r.jpg">
                    </img>
                    <div className='guanwang2_head_logo_div_text'>
                        启程旅游咨询
                    </div>
                </div>
            </div>
            <div className={'guanwang2_head_contact'}>
                <div className={'guanwang2_head_contact_div'}>联系我们</div>
            </div>
        </div>
    )

    var cc = 0


    function handleLeftButton () {
        console.log('左滑')
        if (cc > 0) {
            cc = cc-1
            const slider = document.querySelector('.guanwang2_main_1_contanier');
            slider.style.transform = `translateX(-${cc * 100}%)`;
        }

    }


    function handleRightButton () {
        console.log('右滑')
        if (cc<2){
            cc = cc+1
            const slider = document.querySelector('.guanwang2_main_1_contanier');
            slider.style.transform = `translateX(-${cc * 100}%)`;
        }


    }

    var img0 = (
        <img className='guanwang2_body_1_img'
             src="https://plus.unsplash.com/premium_photo-1704617380718-6ac4984acac2?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHx0b3BpYy1mZWVkfDEzfEZ6bzN6dU9ITjZ3fHxlbnwwfHx8fHw%3D">
        </img>
    )

    var img1 = (
        <img className='guanwang2_body_1_img'
             src="https://images.unsplash.com/photo-1719937051176-9b98352a6cf4?q=80&w=2372&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDF8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D">
        </img>
    )

    var img2 = (
        <img className='guanwang2_body_1_img'
             src="https://images.unsplash.com/photo-1727873817701-8cd132afbb01?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxmZWF0dXJlZC1waG90b3MtZmVlZHw1Nnx8fGVufDB8fHx8fA%3D%3D">

        </img>)

    const body = (
        <div className={'guanwang2_body'}>
            <div className={'guanwang2_body_div1'}>
                <div className='guanwang2_body_main_1'>
                    <div className='guanwang2_main_1_contanier'>
                        {img0}{img1}{img2}
                    </div>
                    <div className='guanwang2_main_1_img_left'
                         onClick={handleLeftButton}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="currentColor"
                             className="bi-arrow-left" viewBox="0 0 16 16">
                            <path fill-rule="evenodd"
                                  d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"/>
                        </svg>
                        左滑
                    </div>
                    <div className='guanwang2_main_1_img_right'
                         onClick={handleRightButton}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="currentColor"
                             className="bi-arrow-right" viewBox="0 0 16 16">
                            <path fill-rule="evenodd"
                                  d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"/>
                        </svg>
                        右滑
                    </div>

                </div>
            </div>


            <div className={'guanwang2_body_div2'}>
                <div className='guanwang2_main_2_text1'>
                    选择我们的四个理由
                </div>
            </div>
            <div className='guanwang2_main_2_line'/>
            <div className='guanwang2_main_2_text2'>
                展现我们对旅游服务的专业承诺，我们对客户体验、文化传承、服务品质以及环境保护的高度重视。相信这些独特的价值主张，能够吸引那些追求高品质、深度体验与责任感并重的旅行者
            </div>


            <div className={'guanwang2_body_div3'}>
                <div className='guanwang2_body_div3_container'>
                    <div className='icon'>
                    </div>
                    <div className='guanwang2_body_div3container_item1'>
                        专业定制，尊享唯一
                    </div>
                    <div className='guanwang2_body_div3_container_item2'>
                        选择我们，就是选择了一场专属于您的旅行盛宴。我们的专业团队将根据您的兴趣与需求，精心规划每一条路线，无论是浪漫的海岛之旅，还是探险的山林之行，都能让您享受到独一无二的旅行体验。拒绝千篇一律，我们只提供量身定制的精彩
                    </div>
                </div>

                <div className='guanwang2_body_div3_container'>
                    <div className='icon'>
                    </div>
                    <div className='guanwang2_body_div3container_item1'>
                        深度体验，文化沉浸
                    </div>
                    <div className='guanwang2_body_div3_container_item2'>
                        我们深知，旅行的真谛在于体验与感悟。因此，我们特别注重带您深入当地，从特色美食到传统节日，从古迹探索到民俗互动，让您不仅作为游客，更作为体验者，全方位沉浸在目的地的文化氛围中，收获满满的知识与感动。
                    </div>
                </div>

                <div className='guanwang2_body_div3_container'>
                    <div className='icon'>
                    </div>
                    <div className='guanwang2_body_div3container_item1'>
                        品质服务，无忧出行
                    </div>
                    <div className='guanwang2_body_div3_container_item2'>
                        从预订那一刻起，直到旅程结束，我们的高品质服务始终伴随左右。无论是接送机的温馨迎接，还是行程中的细致关怀，甚至是紧急情况下的迅速响应，我们都力求做到最好，确保您的旅途顺畅无忧。选择我们，就是选择了一个可以放心的旅行伙伴。
                    </div>
                </div>

                <div className='guanwang2_body_div3_container'>
                    <div className='icon'>
                    </div>
                    <div className='guanwang2_body_div3container_item1'>
                        绿色旅行，责任同行
                    </div>
                    <div className='guanwang2_body_div3_container_item2'>
                        我们倡导并实践绿色旅游理念，致力于保护每一处旅行目的地的自然环境与文化遗产。在享受自然美景的同时，我们也积极参与环保活动，鼓励低碳出行，让每一次旅行都成为对地球负责的行动。选择我们，意味着您也在为可持续旅游贡献一份力量，共同守护这个美丽的星球。
                    </div>
                </div>


            </div>


        </div>
    )

    const foot  = (
        <div className='guanwang2_foot'>
            <div className='guanwang2_foot_grip2_container'>
                <div className='guanwang2_foot_grip2'>
                    解决方案
                </div>

            </div>

            <Divider variant="middle"/>

            <div className='guanwang2_foot_grip2_container'>
                <div className='guanwang2_foot_grip2'>
                    产品服务
                </div>

            </div>
            <Divider variant="middle"/>


            <div className='guanwang2_foot_grip2_container'>
                <div className='guanwang2_foot_grip2'>
                    行业资源
                </div>

            </div>
            <Divider variant="middle"/>

            <div className='guanwang2_foot_grip2_container'>
                <div className='guanwang2_foot_grip2'>
                    定价规则
                </div>

            </div>
            <Divider variant="middle"/>

            <div className='guanwang2_foot_grip2_container'>
                <div className='guanwang2_foot_grip2'>
                    学习资源
                </div>

            </div>
            <div className='guanwang2_foot_grip2_line'></div>

            <div className='guanwang2_foot_grip2_container'>
                <div className='guanwang2_foot_grip2'>
                    技术支持
                </div>

            </div>
            <div className='guanwang2_foot_grip2_line'></div>

            <div className='guanwang2_foot_grip1'>
                Copyright © 2002-2029 某某启程科技有限公司 版权所有 豫ICP备278959843号-1
            </div>
        </div>
    )

    const [isWidthLessThan1000, setIsWidthLessThan1000] = useState(window.innerWidth<1000);
    useEffect(() => {


        const handleResize = () => {
            setIsWidthLessThan1000(window.innerWidth < 870);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);





    return (
        <div className='guanwang2'>
            {isWidthLessThan1000 ?head2:head}
            {body}
            {foot}
        </div>

    );
}
