import {useEffect, useState} from "react";
import './guanwang1.css';



export default function Guanwang1() {

    const [isWidthLessThan1000, setIsWidthLessThan1000] = useState(window.innerWidth<1000);

    useEffect(() => {
        const handleResize = () => {
            setIsWidthLessThan1000(window.innerWidth < 1000);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);


    var cc = 0


    function handleLeftButton () {
        console.log('左滑')

        if (cc > 0) {
            cc = cc-1
            const slider = document.querySelector('.App_lxq_guanwang_main_1_contanier');
            slider.style.transform = `translateX(-${cc * 100}%)`;
        }

    }


    function handleRightButton () {
        console.log('右滑')
        if (cc<2){
            cc = cc+1
            const slider = document.querySelector('.App_lxq_guanwang_main_1_contanier');
            slider.style.transform = `translateX(-${cc * 100}%)`;
        }


    }
        var img0 = (
            <img className='App_lxq_guanwang_main_1_img'
                 src="https://plus.unsplash.com/premium_photo-1704617380718-6ac4984acac2?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHx0b3BpYy1mZWVkfDEzfEZ6bzN6dU9ITjZ3fHxlbnwwfHx8fHw%3D">
            </img>
        )

        var img1 = (
            <img className='App_lxq_guanwang_main_1_img'
                src="https://images.unsplash.com/photo-1719937051176-9b98352a6cf4?q=80&w=2372&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDF8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D">
            </img>
        )

        var img2 = (
        <img className='App_lxq_guanwang_main_1_img'
             src="https://images.unsplash.com/photo-1727873817701-8cd132afbb01?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxmZWF0dXJlZC1waG90b3MtZmVlZHw1Nnx8fGVufDB8fHx8fA%3D%3D">

        </img>)

    var headcontent1 = (
        <div className='App_lxq_guanwang_head'>
        <div className='App_lxq_guanwang_head_content'>
            <div className='App_lxq_guanwang_head_logo'>
                <img className='App_lxq_guanwang_head_logo_img'
                     src="https://pic3.zhimg.com/100/v2-2acc10ea7b4996188e63ed9c9a9503fe_r.jpg">
                </img>
                <div className='App_lxq_guanwang_head_logo_text'>
                    启程咨询服务
                </div>
            </div>
            <div className='App_lxq_guanwang_head_nav'>
                <div className='App_lxq_guanwang_head_nav_group'>
                    解决方案
                </div>
                <div className='App_lxq_guanwang_head_nav_group'>
                    产品
                </div>
                <div className='App_lxq_guanwang_head_nav_group'>
                    行业
                </div>
                <div className='App_lxq_guanwang_head_nav_group'>
                    AI
                </div>
                <div className='App_lxq_guanwang_head_nav_group'>
                    定价
                </div>
                <div className='App_lxq_guanwang_head_nav_group'>
                    资源
                </div>
                <div className='App_lxq_guanwang_head_nav_group'>
                    关于我们
                </div>
            </div>
            <div className='App_lxq_guanwang_head_right'>
                <div className='App_lxq_guanwang_head_right_group2'>
                         联系我们
                </div>
            </div>
        </div>
        </div>
    )

    var [isZhankai,setIsZhankai] = useState(false)

    function zhankai() {
        setIsZhankai(true)
    }

    function guanbi(){
        setIsZhankai(false)
    }

    // isZhankai == false
    var zhedie = (
        <div onClick={zhankai}>
            <svg xmlns="http://www.w3.org/2000/svg"
                 width="26" height="26" fill="currentColor"
                 className="bi-justify"
                 viewBox="0 0 16 16">
                <path fill-rule="evenodd"
                      d="M2 12.5a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5zm0-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5zm0-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5zm0-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5z"/>
            </svg>
        </div>
    )

    // isZhankai == true
    var zhankai = (
        <div>
            <div onClick={guanbi}>
                <svg xmlns="http://www.w3.org/2000/svg"
                     width="36" height="36" fill="currentColor"
                     className="bi-x" viewBox="0 0 16 16">
                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                </svg>
            </div>
            <div className='App_lxq_guanwang_head_zhankai'>
                <div className='App_lxq_guanwang_head_zhankai_item'>
                    解决方案
                </div>
                <div className='App_lxq_guanwang_head_zhankai_item'>
                    产品
                </div>
                <div className='App_lxq_guanwang_head_zhankai_item'>
                    行业
                </div>
                <div className='App_lxq_guanwang_head_zhankai_item'>
                    AI
                </div>
                <div className='App_lxq_guanwang_head_zhankai_item'>
                    定价
                </div>
                <div className='App_lxq_guanwang_head_zhankai_item'>
                    资源
                </div>
                <div className='App_lxq_guanwang_head_zhankai_item'>
                    关于我们
                </div>
                <div className='App_lxq_guanwang_head_zhankai_item'>
                    联系我们
                </div>
            </div>
        </div>

    )

    var headcontent2 = (
        <div className='App_lxq_guanwang_head'>
        <div className='App_lxq_guanwang_head_content'>
                {isZhankai ? zhankai:zhedie}
                <div className='App_lxq_guanwang_head_logo'>
                    <img className='App_lxq_guanwang_head_logo_img'
                         src="https://pic3.zhimg.com/100/v2-2acc10ea7b4996188e63ed9c9a9503fe_r.jpg">
                    </img>
                    <div className='App_lxq_guanwang_head_logo_text'>
                        旅游服务中心
                    </div>
                </div>


            </div>
        </div>
    )

    var main = (
        <div className='App_lxq_guanwang_main'>
            <div className='App_lxq_guanwang_main_1'>
                <div className='App_lxq_guanwang_main_1_contanier'>
                    {img0}{img1}{img2}
                </div>
                <div className='App_lxq_guanwang_main_1_img_left'
                     onClick={handleLeftButton}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="currentColor"
                         className="bi-arrow-left" viewBox="0 0 16 16">
                        <path fill-rule="evenodd"
                              d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"/>
                    </svg>
                    左滑
                </div>
                <div className='App_lxq_guanwang_main_1_img_right'
                     onClick={handleRightButton}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="currentColor"
                         className="bi-arrow-right" viewBox="0 0 16 16">
                        <path fill-rule="evenodd"
                              d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"/>
                    </svg>
                    右滑
                </div>

            </div>

            <div className='App_lxq_guanwang_main_2'>
                <div className='App_lxq_guanwang_main_2_text1'>
                    选择我们的四个理由
                </div>
                <div className='App_lxq_guanwang_main_2_line'>
                </div>
                <div className='App_lxq_guanwang_main_2_text2'>
                    致力于华侨港澳台联合招生考试辅导、教育移居、出国留学等综合性教育移居服务，本中心依托北京及马来西亚一流教育资源。
                </div>

                <div className='App_lxq_guanwang_main_2_text3'>
                    <div className='App_lxq_guanwang_main_2_text3_container'>
                        <div className='icon'>
                        </div>
                        <div className='App_lxq_guanwang_main_2_text3_container_item1'>
                            尽华侨生优惠录取政策
                        </div>
                        <div className='App_lxq_guanwang_main_2_text3_container_item2'>
                            为更好的服务于国内及东南亚地区的家长和考生，在马来西亚设立华侨港澳台学生联考辅导中心，全日制寄宿授课，专业辅导华侨生联考课程；全封闭式管理的“马来西亚国际教育基地”
                        </div>
                    </div>


                    <div className='App_lxq_guanwang_main_2_text3_container'>

                        <div className='icon'>
                        </div>
                        <div className='App_lxq_guanwang_main_2_text3_container_item1'>
                            优质的教学服务
                        </div>
                        <div className='App_lxq_guanwang_main_2_text3_container_item2'>
                            985名校直通车的显著优势在于“录取成绩很低，考试题目容易”，200分起上名校，可以轻松助力那些成绩一般的学生也能圆北大、清华、人大、中财、北理工、武大等中国名校之梦！
                        </div>
                    </div>


                    <div className='App_lxq_guanwang_main_2_text3_container'>

                        <div className='icon'>
                        </div>
                        <div className='App_lxq_guanwang_main_2_text3_container_item1'>
                            雄厚的师资力量
                        </div>
                        <div className='App_lxq_guanwang_main_2_text3_container_item2'>
                            依托北京大学、清华大学、人民大学、马来西亚国际教育基地等国内外一流高校资源，汇集国内外一流名师，为中国大陆的莘莘学子量身打造的直通中外一流大学的快捷通道。
                        </div>
                    </div>

                    <div className='App_lxq_guanwang_main_2_text3_container'>

                        <div className='icon'>
                        </div>
                        <div className='App_lxq_guanwang_main_2_text3_container_item1'>
                            联考留学双保险
                        </div>
                        <div className='App_lxq_guanwang_main_2_text3_container_item2'>
                            可选择马来西亚双语环境同步教育，进入世界名校直通车计划直接申请欧美名校；可谓“进可攻，退可守，联考留学双保险”，确保让每一名莘莘学子都可以成为真正的精英之才！
                        </div>
                    </div>
                </div>

            </div>

        </div>

    )

    var wei = (
        <div className='App_lxq_guanwang_wei'>
            <div className='App_lxq_guanwang_wei_grip2_container'>
                <div className='App_lxq_guanwang_wei_grip2'>
                    解决方案
                </div>

            </div>


            <div className='App_lxq_guanwang_wei_grip2_line'></div>
            <div className='App_lxq_guanwang_wei_grip2_container'>
                <div className='App_lxq_guanwang_wei_grip2'>
                    产品服务
                </div>

            </div>
            <div className='App_lxq_guanwang_wei_grip2_line'></div>

            <div className='App_lxq_guanwang_wei_grip2_container'>
                <div className='App_lxq_guanwang_wei_grip2'>
                    行业资源
                </div>

            </div>
            <div className='App_lxq_guanwang_wei_grip2_line'></div>

            <div className='App_lxq_guanwang_wei_grip2_container'>
                <div className='App_lxq_guanwang_wei_grip2'>
                    定价规则
                </div>

            </div>
            <div className='App_lxq_guanwang_wei_grip2_line'></div>

            <div className='App_lxq_guanwang_wei_grip2_container'>
                <div className='App_lxq_guanwang_wei_grip2'>
                    学习资源
                </div>

            </div>
            <div className='App_lxq_guanwang_wei_grip2_line'></div>

            <div className='App_lxq_guanwang_wei_grip2_container'>
                <div className='App_lxq_guanwang_wei_grip2'>
                    技术支持
                </div>

            </div>
            <div className='App_lxq_guanwang_wei_grip2_line'></div>

            <div className='App_lxq_guanwang_wei_grip1'>
                Copyright © 2002-2029 某某启程科技有限公司 版权所有 豫ICP备278959843号-1
            </div>
        </div>
    )

    return (
        <div className='App_lxq'>
            {headcontent1}
            {isWidthLessThan1000 ? headcontent2 : headcontent1}
            {main}
            {wei}
        </div>
    );
}
