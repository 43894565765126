import {useEffect, useState} from "react";
import './guanwang4.css';


export default function Guanwang4() {
    return (
        <div className='App_lxq'>
            hello guanwang4
        </div>
    );
}