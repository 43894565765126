import './App.css';
import {
  Route,
  Routes,

} from "react-router-dom";
import { BrowserRouter } from "react-router-dom";


import Guanwang1 from "./reactRouter/guanwang/guanwang1";
import Guanwang2 from "./reactRouter/guanwang/guanwang2";
import Guanwang3 from "./reactRouter/guanwang/guanwang3";
import Guanwang4 from "./reactRouter/guanwang/guanwang4";
import Guanwang100 from "./reactRouter/guanwang/guanwang100";


// import ErrorPage from "./error-page";
function App() {

  return (
      <BrowserRouter>
        {/*// <BrowserRouter basename={"/app"}>*/}
        {/* The rest of your app goes here */}
        <Routes>

          {/*官网项目*/}
          <Route path="/" element={<Guanwang1 />} />
          <Route path="/guanwang1" element={<Guanwang1 />} />
          <Route path="/guanwang2" element={<Guanwang2 />} />
          <Route path="/guanwang3" element={<Guanwang3 />} />
          <Route path="/guanwang4" element={<Guanwang4 />} />
          <Route path="/guanwang100" element={<Guanwang100 />} />

        </Routes>
      </BrowserRouter>


  );
}

export default App;




