import {useEffect, useState} from "react";
import './guanwang2.css';
import {Button} from "@mui/material";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import DeleteIcon from '@mui/icons-material/Delete';
import SendIcon from '@mui/icons-material/Send';

import IconButton from '@mui/material/IconButton';
import AlarmIcon from '@mui/icons-material/Alarm';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import Fingerprint from '@mui/icons-material/Fingerprint';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { styled } from '@mui/material/styles';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import Stack from '@mui/material/Stack';

import Checkbox from '@mui/material/Checkbox';

import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { green, pink } from '@mui/material/colors';
import FavoriteBorder from '@mui/icons-material/FavoriteBorder';
import Favorite from '@mui/icons-material/Favorite';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import BookmarkIcon from '@mui/icons-material/Bookmark';

import Box from '@mui/material/Box';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import FavoriteIcon from '@mui/icons-material/Favorite';
import NavigationIcon from '@mui/icons-material/Navigation';

import Slider from '@mui/material/Slider';
import VolumeDown from '@mui/icons-material/VolumeDown';
import VolumeUp from '@mui/icons-material/VolumeUp';
import Switch from '@mui/material/Switch';
import Avatar from '@mui/material/Avatar';
import { deepOrange, deepPurple } from '@mui/material/colors';
import FolderIcon from '@mui/icons-material/Folder';
import PageviewIcon from '@mui/icons-material/Pageview';
import AssignmentIcon from '@mui/icons-material/Assignment';
import Badge from '@mui/material/Badge';
import MailIcon from '@mui/icons-material/Mail';
import Divider from '@mui/material/Divider';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Alert from '@mui/material/Alert';
import CheckIcon from '@mui/icons-material/Check';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import LinearProgress from '@mui/material/LinearProgress';

import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import MenuIcon from '@mui/icons-material/Menu';
import Paper from '@mui/material/Paper';
import Pagination from '@mui/material/Pagination';

import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import FileCopyIcon from '@mui/icons-material/FileCopyOutlined';
import PrintIcon from '@mui/icons-material/Print';
import ShareIcon from '@mui/icons-material/Share';


export default function Guanwang2() {



    const style = {
        py: 0,
        width: '100%',
        maxWidth: 360,
        borderRadius: 2,
        border: '1px solid',
        borderColor: 'divider',
        backgroundColor: 'background.paper',
    };

    const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

    function handle_button(){
        alert('clicked');
    }

    const VisuallyHiddenInput = styled('input')({
        clip: 'rect(0 0 0 0)',
        clipPath: 'inset(50%)',
        height: 1,
        overflow: 'hidden',
        position: 'absolute',
        bottom: 0,
        left: 0,
        whiteSpace: 'nowrap',
        width: 1,
    });
    const [checked, setChecked] = useState(true);

    const handleChange = (event) => {
        setChecked(event.target.checked);
    };
    const [value, setValue] = useState(30);

    const handleChange2 = (event,newValue) => {
        setValue(newValue);
    };
    const [open, setOpen] = useState(false);
    const handleClose = () => {
        setOpen(false);
    };
    const handleOpen = () => {
        setOpen(true);
    };
    const actions = [
        { icon: <FileCopyIcon />, name: 'Copy' },
        { icon: <SaveIcon />, name: 'Save' },
        { icon: <PrintIcon />, name: 'Print' },
        { icon: <ShareIcon />, name: 'Share' },
    ];

    return (
        <div className='App_lxq'>
            <span>先这么多吧，其他的具体再看吧，写的很好</span>
            <span>文档：https://mui.com/toolpad/core/react-persistent-state/</span>
            <span>34</span>
            <Box sx={{ height: 320, transform: 'translateZ(0px)', flexGrow: 1 }}>
                <SpeedDial
                    ariaLabel="SpeedDial basic example"
                    sx={{ position: 'absolute', bottom: 16, right: 16 }}
                    icon={<SpeedDialIcon />}
                >
                    {actions.map((action) => (
                        <SpeedDialAction
                            key={action.name}
                            icon={action.icon}
                            tooltipTitle={action.name}
                        />
                    ))}
                </SpeedDial>
            </Box>
            <span>33</span>
            <Stack spacing={2}>
                <Pagination count={10} />
                <Pagination count={10} color="primary" />
                <Pagination count={10} color="secondary" />
                <Pagination count={10} disabled />
            </Stack>
            <span>32</span>
            <Box
                sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    '& > :not(style)': {
                        m: 1,
                        width: 128,
                        height: 128,
                    },
                }}
            >
                <Paper elevation={0} />
                <Paper />
                <Paper elevation={3} />
            </Box>

            <span>31</span>
            <Box sx={{ flexGrow: 1 }}>
                <AppBar position="static">
                    <Toolbar>
                        <IconButton
                            size="large"
                            edge="start"
                            color="inherit"
                            aria-label="menu"
                            sx={{ mr: 2 }}
                        >
                            <MenuIcon />
                        </IconButton>
                        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                            News
                        </Typography>
                        <Button color="inherit">Login</Button>
                    </Toolbar>
                </AppBar>
            </Box>
            <span>30</span>
            <LinearProgress />
            <CircularProgress color="secondary" />
            <CircularProgress color="success" />
            <CircularProgress color="inherit" />
            <span>29</span>
            <div>
                <Button onClick={handleOpen}>Show backdrop</Button>
                <Backdrop
                    sx={(theme) => ({color: '#fff', zIndex: theme.zIndex.drawer + 1})}
                    open={open}
                    onClick={handleClose}
                >
                    <CircularProgress color="inherit"/>
                </Backdrop>
            </div>
            <span>28</span>
            <Alert severity="success">This is a success Alert.</Alert>
            <Alert severity="info">This is an info Alert.</Alert>
            <Alert severity="warning">This is a warning Alert.</Alert>
            <Alert severity="error">This is an error Alert.</Alert>
            <Alert icon={<CheckIcon fontSize="inherit"/>} severity="success">
                Here is a gentle confirmation that your action was successful.
            </Alert>
            <span>27</span>
            <List sx={style}>
                <ListItem>
                    <ListItemText primary="Full width variant below"/>
                </ListItem>
                <Divider component="li"/>
                <ListItem>
                    <ListItemText primary="Inset variant below"/>
                </ListItem>
                <Divider variant="inset" component="li"/>
                <ListItem>
                    <ListItemText primary="Middle variant below"/>
                </ListItem>
                <Divider variant="middle" component="li"/>
                <ListItem>
                    <ListItemText primary="List item"/>
                </ListItem>
            </List>
            <span>26</span>
            <Divider/>
            <span>25</span>
            <Badge color="secondary" badgeContent={99}>
                <MailIcon/>
            </Badge>
            <Badge color="secondary" badgeContent={100}>
                <MailIcon/>
            </Badge>
            <Badge color="secondary" badgeContent={1000} max={999}>
                <MailIcon/>
            </Badge>
            <Stack spacing={2} direction="row">
                <Badge badgeContent={4} color="secondary">
                    <MailIcon color="action"/>
                </Badge>
                <Badge badgeContent={4} color="success">
                    <MailIcon color="action"/>
                </Badge>
            </Stack>
            <Badge badgeContent={4} color="primary">
                <MailIcon color="action"/>
            </Badge>
            <span>24</span>
            <Stack direction="row" spacing={2}>
                <Avatar>
                    <FolderIcon/>
                </Avatar>
                <Avatar sx={{bgcolor: pink[500]}}>
                    <PageviewIcon/>
                </Avatar>
                <Avatar sx={{bgcolor: green[500]}}>
                    <AssignmentIcon/>
                </Avatar>
            </Stack>
            <Stack direction="row" spacing={2}>
                <Avatar>H</Avatar>
                <Avatar sx={{bgcolor: deepOrange[500]}}>N</Avatar>
                <Avatar sx={{bgcolor: deepPurple[500]}}>OP</Avatar>
            </Stack>
            <span>23</span>
            <Box
                component="form"
                sx={{'& > :not(style)': {m: 1, width: '25ch'}}}
                noValidate
                autoComplete="off"
            >
                <TextField id="outlined-basic" label="Outlined" variant="outlined"/>
                <TextField id="filled-basic" label="Filled" variant="filled"/>
                <TextField id="standard-basic" label="Standard" variant="standard"/>
            </Box>
            <span>22</span>
            <div>
                <Switch {...label} defaultChecked/>
                <Switch {...label} />
                <Switch {...label} disabled defaultChecked/>
                <Switch {...label} disabled/>
            </div>
            <span>21</span>
            <Box sx={{width: 300}}>
                <Slider
                    size="small"
                    defaultValue={70}
                    aria-label="Small"
                    valueLabelDisplay="auto"
                />
                <Slider defaultValue={50} aria-label="Default" valueLabelDisplay="auto"/>
            </Box>
            <span>20</span>
            <Box sx={{width: 200}}>
                <Stack spacing={2} direction="row" sx={{alignItems: 'center', mb: 1}}>
                    <VolumeDown/>
                    <Slider aria-label="Volume" value={value} onChange={handleChange2}/>
                    <VolumeUp/>
                </Stack>
                <Slider disabled defaultValue={30} aria-label="Disabled slider"/>
            </Box>
            <span>19</span>
            <Box sx={{'& > :not(style)': {m: 1}}}>
                <Fab variant="extended" size="small" color="primary">
                    <NavigationIcon sx={{mr: 1}}/>
                    Extended
                </Fab>
                <Fab variant="extended" size="medium" color="primary">
                    <NavigationIcon sx={{mr: 1}}/>
                    Extended
                </Fab>
                <Fab variant="extended" color="primary">
                    <NavigationIcon sx={{mr: 1}}/>
                    Extended
                </Fab>
            </Box>
            <Box sx={{'& > :not(style)': {m: 1}}}>
                <Fab size="small" color="secondary" aria-label="add">
                    <AddIcon/>
                </Fab>
                <Fab size="medium" color="secondary" aria-label="add">
                    <AddIcon/>
                </Fab>
                <Fab color="secondary" aria-label="add">
                    <AddIcon/>
                </Fab>
            </Box>

            <Box sx={{'& > :not(style)': {m: 1}}}>
                <Fab color="primary" aria-label="add">
                    <AddIcon/>
                </Fab>
                <Fab color="secondary" aria-label="edit">
                    <EditIcon/>
                </Fab>
                <Fab variant="extended">
                    <NavigationIcon sx={{mr: 1}}/>
                    Navigate
                </Fab>
                <Fab disabled aria-label="like">
                    <FavoriteIcon/>
                </Fab>
            </Box>
            <span>18</span>
            <Checkbox
                checked={checked}
                onChange={handleChange}
                inputProps={{'aria-label': 'controlled'}}
            />
            <span>17</span>
            <div>
                <Checkbox {...label} icon={<FavoriteBorder/>} checkedIcon={<Favorite/>}/>
                <Checkbox
                    {...label}
                    icon={<BookmarkBorderIcon/>}
                    checkedIcon={<BookmarkIcon/>}
                />
            </div>
            <span>16</span>
            <div>
                <Checkbox {...label} defaultChecked/>
                <Checkbox {...label} defaultChecked color="secondary"/>
                <Checkbox {...label} defaultChecked color="success"/>
                <Checkbox {...label} defaultChecked color="default"/>
                <Checkbox
                    {...label}
                    defaultChecked
                    sx={{
                        color: pink[800],
                        '&.Mui-checked': {
                            color: pink[600],
                        },
                    }}
                />
            </div>
            <span>15</span>
            <div>
                <Checkbox {...label} defaultChecked size="small"/>
                <Checkbox {...label} defaultChecked/>
                <Checkbox
                    {...label}
                    defaultChecked
                    sx={{'& .MuiSvgIcon-root': {fontSize: 28}}}
                />
            </div>
            <span>14</span>
            <FormGroup>
                <FormControlLabel control={<Checkbox defaultChecked/>} label="Label"/>
                <FormControlLabel required control={<Checkbox/>} label="Required"/>
                <FormControlLabel disabled control={<Checkbox/>} label="Disabled"/>
            </FormGroup>
            <span>13</span>
            <div>
                <Checkbox {...label} defaultChecked/>
                <Checkbox {...label} />
                <Checkbox {...label} disabled/>
                <Checkbox {...label} disabled checked/>
            </div>

            <span>12</span>
            <Autocomplete
                disablePortal
                options={['aaa', 'bbb', 'ccc', 'ddd', 'eee']}
                sx={{width: 300}}
                renderInput={(params) => <TextField {...params} label="Movie"/>}
            />

            <span>11</span>
            <Stack direction="row" spacing={2}>
                <LoadingButton loading variant="outlined">
                    Submit
                </LoadingButton>
                <LoadingButton loading loadingIndicator="Loading…" variant="outlined">
                    Fetch data
                </LoadingButton>
                <LoadingButton
                    loading
                    loadingPosition="start"
                    startIcon={<SaveIcon/>}
                    variant="outlined"
                >
                    Save
                </LoadingButton>
            </Stack>

            <span>9</span>
            <Button
                component="label"
                role={undefined}
                variant="contained"
                tabIndex={-1}
                startIcon={<CloudUploadIcon/>}
            >
                Upload files
                <VisuallyHiddenInput
                    type="file"
                    onChange={(event) => console.log(event.target.files)}
                    multiple
                />
            </Button>

            <span>8</span>
            <Button variant="text">Text</Button>
            <Button variant="contained">Contained</Button>
            <Button variant="outlined">Outlined</Button>
            <Button>Primary</Button>
            <Button disabled>Disabled</Button>
            <Button href="#text-buttons">Link</Button>
            <Button variant="contained">Contained</Button>
            <Button variant="contained" disabled>
                Disabled
            </Button>
            <Button variant="contained" href="#contained-buttons">
                Link
            </Button>

            <span>7</span>
            <Button
                onClick={handle_button}
            >
                Click me
            </Button>

            <span>6</span>
            <Button color="success">Secondary</Button>
            <Button variant="contained" color="success">
                Success
            </Button>
            <Button variant="outlined" color="error">
                Error
            </Button>

            <div>

                <span>5</span>
                <Button variant="contained" size="small">
                    Small
                </Button>
                <Button variant="contained" size="medium">
                    Medium
                </Button>
                <Button variant="contained" size="large">
                    Large
                </Button>

                <span>4</span>
                <Button variant="outlined" startIcon={<DeleteIcon/>}>
                    Delete
                </Button>
                <Button variant="contained" endIcon={<SendIcon/>}>
                    Send
                </Button>

                <span>3</span>
                <IconButton color="secondary" aria-label="add an alarm">
                    <AlarmIcon/>
                </IconButton>
                <IconButton color="primary" aria-label="add to shopping cart">
                    <AddShoppingCartIcon/>
                </IconButton>

                <span>2</span>
                <IconButton aria-label="delete" size="small">
                    <DeleteIcon fontSize="inherit"/>
                </IconButton>
                <IconButton aria-label="delete" size="small">
                    <DeleteIcon fontSize="small"/>
                </IconButton>
                <IconButton aria-label="delete" size="large">
                    <DeleteIcon/>
                </IconButton>
                <IconButton aria-label="delete" size="large">
                    <DeleteIcon fontSize="inherit"/>
                </IconButton>

                <span>1</span>
                <IconButton aria-label="fingerprint" color="secondary">
                    <Fingerprint/>
                </IconButton>
                <IconButton aria-label="fingerprint" color="success">
                    <Fingerprint/>
                </IconButton>
            </div>


        </div>
    );
}



