import {useEffect, useState} from "react";
import './guanwang100.css';


export default function Guanwang100() {
    return (
        <div className='App_lxq'>
            hello guanwang100
        </div>
    );
}